import { genArray } from '@vjs/helpers';
import {
  VVoucherQueueFilters,
  VVoucherQueueCategories,
  VVoucherQueueCategory,
  VVoucherQueueStatistic,
} from './components';
import { scrollTop } from '../../../helpers';

export default {
  name: 'VVoucherQueue',
  components: {
    VVoucherQueueFilters,
    VVoucherQueueCategories,
    VVoucherQueueCategory,
    VVoucherQueueStatistic,
  },
  props: {
    typeOptions: {
      type: Array,
      required: true,
    },
    uriGetByCategory: {
      type: String,
      required: true,
    },
    uriSendStatement: {
      type: String,
      required: true,
    },
    uriLocality: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      staticUrl: window.core_project.cdnStatic,
      activeCategory: null,
      itemsByCategory: [],
      refsByCategory: {},
      statisticItems: [],
      filters: {},
      loading: true,
      swiping: false,
      type: JSON.parse(sessionStorage.getItem('queueVoucherFilter'))?.type ?? 'regular',
    };
  },
  beforeCreate() {
    this.$trans.add(['queue', 'button', 'warning_text', 'bulletin', 'statistics']);
  },

  computed: {
    isAdaptive() {
      return this.$q.screen.width < 530;
    },
    activeCategoryName() {
      return this.items.find(itm => itm.id === this.activeCategory)?.name;
    },
    categoryCountLabel() {
      const { type, trans } = this;
      return type === 'regular'
        ? trans('queue.voucher_queue_count')
        : trans('queue.queue_count_default');
    },
    swipeDirection() { return this.activeCategory ? 'swipe-left' : 'swipe-right'; },
    itemsComputed() {
      if (this.loading) {
        return genArray({}, 4);
      }
      return this.items ?? [];
    },
  },
  methods: {
    handleSwiping() {
      this.swiping = true;
      setTimeout(() => {
        this.swiping = false;
      }, 300);
    },
    // Принимает и обрабатывает список от фильтра
    async handleFilteredList(res) {
      this.items = res.items ?? [];
      this.filters = res.filters ?? {};
      this.searchValue = '';
      this.statisticItems = res.statistics ?? [];
    },
    // Запускается на событие @input в q-pagination
    scrollToTop() {
      // Перевод страницы к началу при выборе страницы
      scrollTop(
        window,
        window.scrollY,
        window.scrollY - Math.abs(this.$refs.catalog.getBoundingClientRect().top),
        500,
      );
    },
    async handleShowCategory(id) {
      this.$globalLoading.show();
      this.scrollToTop();
      const res = await requestGetDataHelper(this, {
        url: this.uriGetByCategory,
        params: { categoryType: id, ...this.filters, type: this.type },
        withLoader: false,
        returnData: true,
      });
      if (res.error) {
        this.activeCategory = null;
      } else {
        this.itemsByCategory = res.items;
        this.refsByCategory = res.refs;
        this.activeCategory = id;
        this.handleSwiping();
      }
      this.$globalLoading.hide();
    },
    handleBackBtn() {
      this.handleSwiping();
      this.activeCategory = null;
      this.itemsByCategory = [];
      this.refsByCategory = {};
    },
  },
};
