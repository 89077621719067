<template>
  <div>
    <v-spoiler
      v-for="(spoiler, spoilerKey) in items"
      :key="spoilerKey"
      :title="spoiler.title"
    >
      <div>
        <div
          v-for="(itm, itmKey) in spoiler.items"
          :key="`${spoilerKey}-${itmKey}`"
          class="d-flex mb-5"
          :class="{
            ['flex-column']: $q.screen.width < 500,
          }"
        >
          <div
            class="s-c-blue"
            :style="$q.screen.width > 499 ? 'margin-right: 0.5rem;' : ''"
          >
            {{ itm.label }}:
          </div>
          <div
            class="s-c-blue s-medium"
            style="min-width: 10rem"
          >
            {{ itm.value }}
          </div>
        </div>
      </div>
    </v-spoiler>
  </div>
</template>

<script>
import VSpoiler from '@common/components/VSpoiler';

export default {
  name: 'NewTableInfoTables',
  components: { VSpoiler },
  props: {
    items: {
      type: [Array],
      default: () => ([]),
    },
  },
};
</script>
