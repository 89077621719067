<template>
  <q-form
    class="s-timesheet-validate-files-modal"
    @submit="request"
  >
    <v-form-inform :message="trans('attendance.validate_files.inform')" />
    <v-wrapper-q-select
      v-model="form.child"
      :options="children"
      :extra-binds="{
        required: true,
        label: trans('timesheet.validate_files.select_child'),
      }"
      @input-full-option="form.days = $event.days"
    />

    <q-slide-transition>
      <div
        v-if="form.child"
        style="max-height: 40rem; position: relative; overflow: auto;"
      >
        <div
          v-for="(day, key) in form.days"
          :key="key"
          class="candidate-item"
        >
          <input
            :id="'day-' + day.id+'-1'"
            :key="'success-' + key"
            v-model="day.value"
            class="__success"
            type="radio"
            :value="1"
            checked
            @input="day.reasonSelected = null"
          >
          <label :for="'day-' + day.id+'-1'" />
          <input
            :id="'day-' + day.id+'-0'"
            :key="'reject-' + key"
            v-model="day.value"
            class="__reject"
            type="radio"
            :value="0"
            @input="day.reasonSelected = null"
          >
          <label :for="'day-' + day.id+'-0'" />
          <q-btn
            no-caps
            color="orange"
            squire
            icon="eye"
            type="a"
            target="_blank"
            :to="day.file"
          >
            <q-tooltip>{{ trans('timesheet.download_file') }}</q-tooltip>
          </q-btn>
          <div class="candidate-item__name">
            <div>{{ day.date }}</div>
            <div>{{ day.reason }}</div>
            <q-slide-transition>
              <v-wrapper-q-select
                v-if="day.value === 0"
                v-model="day.reasonSelected"
                :options="refs.reasons"
                :use-input="false"
                :extra-binds="{
                  required: true,
                  placeholder: trans('timesheet.validate_files.select_reason'),
                  hideBottomSpace: true,
                }"
              />
            </q-slide-transition>
          </div>
        </div>
      </div>
    </q-slide-transition>

    <div class="row-sort-between-btn mt-10">
      <q-btn
        no-caps
        width="15"
        color="grey"
        :label="trans('button.cancel')"
        @click="$emit('onClose')"
      />
      <q-btn
        no-caps
        width="15"
        color="green"
        :label="trans('button.approve')"
        type="submit"
        :disable="!form.child || hasDeclinedWithoutReason"
      />
    </div>
  </q-form>
</template>

<style lang="scss">
.s-timesheet-validate-files-modal {
  .candidate-item {
    margin-bottom: 1rem;

    .sn-btn {
      order: 2;
      border-radius: unset;
      box-shadow: unset;
      width: 5rem;
    }

    input[type=radio].__success + label {
      order: 3;
    }

    input[type=radio].__reject + label {
      order: 4;
    }

    &__name {
      padding: 0.75rem;
      font-size: 14px;
      line-height: 18px;
    }

    .sn-field__wrapper {
      padding: unset;

      .sn-field__control {
        min-height: unset;
        height: unset;
        border-color: #F46390;

        .sn-field__native {
          padding: 0;
          span {
            white-space: unset;
            line-height: 14px;
          }
          input {
            font-size: 14px;
            color: #F46390;
            text-align: unset;
          }
        }
      }
    }
  }
}
</style>

<script>
import { Helper } from '@common/src/helpers';

export default {
  props: {
    action: {
      type: String,
      required: true,
    },
    refs: {
      type: Object,
      default: () => ({
        reasons: [],
      }),
    },
    attendance: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        child: null,
        days: [],
      },
    };
  },
  computed: {
    hasDeclinedWithoutReason() {
      return !!this.form.days.find(itm => itm.value === 0 && !itm.reasonSelected);
    },
    children() {
      const { attendance } = this;
      const result = [];
      const filteredAttendances = attendance.filter(item => item.respectfulAbsentDays > 0);
      _.forEach(filteredAttendances, (item) => {
        const days = [];
        _.forEach(item.days, (day) => {
          const DT = day.attendance;
          if (DT.attendance === 0 && DT['has-file'] && !DT.reviewed) {
            days.push({
              id: DT.id,
              date: moment(DT.date).format('DD.MM.YYYY'),
              reason: DT.absent_reason_name,
              file: DT.file,
              value: 1,
              reasonSelected: null,
            });
          }
        });
        if (days?.length) {
          result.push({ name: item.full_name, id: item.child_id, days });
        }
      });

      return result;
    },
  },
  methods: {
    async request() {
      this.$globalLoading.show();
      const data = {
        child: this.form.child,
        reason: this.form.reason,
        dates: this.form.days.map(itm => ({
          id: itm.id,
          value: itm.value,
          date: itm.date,
          reason: itm.reasonSelected,
        })),
        date: this.date,
      };

      const res = await requestWrapper.call(this, {
        url: this.action,
        method: 'post',
        data,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
