<template>
  <div>
    <div class="row justify-content-center">
      <v-wrapper-q-date
        v-model="dateEnd"
        :extra-binds="{
          label: trans('statement_contract.new_contract_end'),
          input: {required: true,}
        }"
      />
      <div class="row-sort-between-btn">
        <q-btn
          width="20"
          :label="trans('button.close')"
          color="grey"
          @click="handleModal"
        />
        <q-btn
          width="20"
          :label="signButtonLabel ?? trans('button.sign_and_send')"
          color="green"
          :disable="!dateEnd"
          @click="handleSendBtn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  QBtn, QDate, QIcon, QInput, QPopupProxy,
} from '@quasar/components';
import { ClosePopup } from '@quasar/directives';
import { Helper } from '@common/src/helpers';

export default {
  name: 'VStatementContractExtendModal',
  components: {
    QPopupProxy,
    QIcon,
    QInput,
    QDate,
    QBtn,
  },
  directives: { ClosePopup },
  props: {
    isVoucher: {
      type: Boolean,
      default: false,
    },
    extendUrl: {
      type: String,
      default: '',
    },
    signButtonLabel: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      signXml: '',
      dateEnd: '',
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement_contract',
      'button',
    ]);
  },
  methods: {
    async handleSendBtn() {
      if (this.isVoucher) {
        await this.request();
      } else {
        await this.handleSign();
      }
    },
    async request() {
      this.$globalLoading.show();
      const res = await requestWrapper.call(this, {
        url: this.extendUrl,
        method: 'post',
        data: {
          dateEnd: this.dateEnd,
        },
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
    async handleSign() {
      this.$globalLoading.show();
      const webSocket = new WebSocket('wss://127.0.0.1:13579/');
      let callback = null;
      const xmlToSign = '<status>Sign</status>';
      if ([webSocket.CLOSED, webSocket.CLOSING].includes(webSocket.readyState)) {
        this.$notify({
          text: this.trans('notice.ncalayer_connection_error'),
          type: 'error',
        });
        return;
      }
      webSocket.onopen = () => {
        callback = async (response) => {
          this.signXml = response.responseObject;
          const res = await requestWrapper.call(this, {
            url: this.extendUrl,
            method: 'post',
            data: {
              dateEnd: this.dateEnd,
              signXml: this.signXml,
            },
          });
          if (!res.error) {
            this.$emit('modalSigned', this.signXml);
          } else {
            this.signXml = '';
          }
          this.$globalLoading.hide();
        };
        webSocket.send(JSON.stringify(
          {
            id: 1,
            module: 'kz.gov.pki.knca.commonUtils',
            method: 'signXml',
            args: ['PKCS12', 'SIGNATURE', xmlToSign, '', ''],
          },
        ));
      };

      webSocket.onclose = (event) => {
        if (event.wasClean) {
          // eslint-disable-next-line no-console
          console.warn('connection has been closed');
        } else {
          this.$notify({
            text: this.trans('notice.ncalayer_connection_closed'),
            type: 'error',
          });
          this.$globalLoading.hide();
        }
      };

      webSocket.onmessage = (event) => {
        const result = JSON.parse(event.data);
        if (result != null) {
          if (result?.code === '200') {
            if (typeof callback === 'function') {
              callback(result);
            }
          } else if (result?.code === '500') {
            this.$globalLoading.hide();
          }
        }
      };
    },
    async handleModal() {
      this.$emit('onClose');
    },
  },
};
</script>

<style scoped>

</style>
