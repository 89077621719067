<template>
  <div>
    <q-table
      ref="mainTable"
      :data="rows"
      :columns="item.cols"
      flat
      separator="cell"
      class="s-b-white sn-table--no-wrap-body s-fc-report__table"
      wrap-cells
      hide-bottom
      style="z-index: 0;"
      :no-data-label="trans('label.no_data')"
      :no-results-label="trans('label.no_results')"
      :pagination.sync="pagination"
      @scrolling="handleScroll"
    >
      <template v-slot:header="props">
        <q-tr :props="props">
          <q-th
            v-for="col in props.cols"
            :ref="`${col.name}Ref`"
            :key="col.name"
            :field="col.field"
            :name="col.name"
            :props="props"
            :style="colStyle(col.name, props)"
          >
            {{ col.label }}
            <q-input
              v-if="['iin', 'fullname'].includes(col.name)"
              v-model="filters[col.name]"
              :debounce="300"
              :placeholder="trans('placeholder.input_placeholder')"
              class="s-fc-report__input"
            />
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <template v-for="(col,i) in props.cols">
            <q-td
              v-if="col.name === 'index'"
              :key="i"
              :style="col.value?.customStyle"
              :align="col.align"
              v-html="props.row.__index"
            />
            <q-td
              v-else
              :key="i"
              :style="col.value?.customStyle"
              :align="col.align"
              v-html="col.value?.value ?? col.value"
            />
          </template>
        </q-tr>
      </template>
    </q-table>
    <q-table
      ref="bottomTable"
      :data="[item.bottomRow]"
      :columns="item.cols"
      flat
      separator="cell"
      class="s-b-white sn-table--no-wrap-body s-fc-report__table s-fc-report__table--bottom"
      wrap-cells
      hide-bottom
      hide-header
    >
      <template v-slot:body="props">
        <q-tr
          :props="props"
          class="s-table-with-filters--bottom-row"
        >
          <template v-for="(col,key) in props.cols">
            <q-td
              v-if="col.value !== null"
              :key="`bottom-row-cell-${key}`"
              :style="getBottomRowStyle(col)"
              :align="col.align"
              :colspan="col.value?.colspan"
              v-html="col.value?.value ?? col.value"
            />
          </template>
        </q-tr>
      </template>
    </q-table>
  </div>
</template>

<script>
import {
  QTable, QTd, QTh, QTr,
} from '@quasar/components';

export default {
  name: 'VVoucherFcReportTable',
  components: {
    QTh, QTd, QTable, QTr,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      filters: {
        iin: '',
        fullname: '',
      },
      pagination: { rowsPerPage: 0 },
      colSizes: {},
    };
  },
  computed: {
    rows() {
      let items = this.item.data;
      if (this.filters.iin !== '') {
        const searchStr = this.filters.iin.toLowerCase();
        items = items.filter(itm => itm.iin.toLowerCase().indexOf(searchStr) !== -1);
      }
      if (this.filters.fullname !== '') {
        const searchStr = this.filters.fullname.toLowerCase();
        items = items.filter(itm => itm.fullname.toLowerCase().indexOf(searchStr) !== -1);
      }
      return items;
    },
  },
  watch: {
    rows: {
      handler() {
        this.reCalcSizes();
      },
      deep: true,
    },
  },
  mounted() {
    this.reCalcSizes();
  },
  methods: {
    handleScroll(val) {
      if (this.$refs.bottomTable) {
        this.$refs.bottomTable.$refs.bottomScrollWrapper.scrollLeft = val;
      }
    },
    handleBottomTableOverflow() {
      const ref = this.$refs.mainTable;
      if (ref) {
        const scrollWrapper = ref.$refs.bottomScrollWrapper;
        if (scrollWrapper.scrollHeight > scrollWrapper.clientHeight) {
          this.$refs.bottomTable.$refs.bottomScrollWrapper.style.overflowY = 'scroll';
        } else {
          this.$refs.bottomTable.$refs.bottomScrollWrapper.style.overflowY = 'hidden';
        }
      }
    },
    reCalcSizes() {
      this.$nextTick(() => {
        let tableWidth = 0;
        _.forEach(this.item.cols, (itm) => {
          const ref = this.$refs[`${itm.name}Ref`];
          if (ref?.[0]?.$el) {
            const { width } = ref[0].$el.getBoundingClientRect();
            this.colSizes[itm.name] = width;
            tableWidth += width;
          }
        });
        if (this.$refs.bottomTable) {
          this.handleBottomTableOverflow();
          this.$refs.bottomTable.$refs.bottomScrollBody.style.width = `${tableWidth}px`;
        }
      });
    },
    getBottomRowStyle({ name, value: { customStyle } }) {
      let width = this.colSizes[name];
      if (name === 'index') {
        width = this.colSizes.index + this.colSizes.iin + this.colSizes.fullname;
      }
      return `${customStyle}; width: ${width}px; max-width: ${width}px;`;
    },
    colStyle(name) {
      switch (name) {
        case 'index':
          return { minWidth: '5.5rem' };
        case 'iin':
          return { minWidth: '14rem' };
        case 'fullname':
          return { minWidth: '35rem' };
        default:
          return { minWidth: '15rem' };
      }
    },
  },
};

</script>
