<template>
  <q-dialog
    :value="isOpen"
    @input="closeModal"
  >
    <v-modal
      :value="isOpen"
      :title="trans('attendance.timesheet-load-title')"
      size="700"
    >
      <v-form-inform
        v-if="!editableDays.length"
        :message="trans('attendance.timesheet-load-no-date')"
      />

      <q-field
        v-if="massEditType > 1"
        borderless
      >
        <q-option-group
          v-model="modalType"
          class="d-flex"
          inline
          :options="modalTypeOptions"
          @click="clearModel"
        />
      </q-field>

      <q-slide-transition>
        <div
          v-if="modalType === 1"
          class="row"
        >
          <div class="col-md-3">
            <q-select
              v-model="selectedDate"
              :label="trans('label.date')"
              :options="editableDays"
              :disable="confirming || reloading"
              required
            />
          </div>
        </div>
        <q-select
          v-else
          v-model="altSelectedChild"
          :label="trans('label.select_from_contingent')"
          :options="editableChildrenAlt"
          :disable="confirming || reloading"
          required
          emit-value
          map-options
          @input="altSelectedDates = []"
        />
      </q-slide-transition>
      <q-slide-transition>
        <q-checkbox
          v-if="altSelectedChild"
          v-model="allDates"
          :label="trans('label.select_all_dates')"
          @input="selectAllDates"
        />
      </q-slide-transition>
      <q-slide-transition>
        <v-wrapper-q-select
          v-if="altSelectedChild"
          v-model="altSelectedDates"
          :options="editableDaysAlt"
          multiple
          option-value="value"
          option-label="label"
          :extra-binds="{
            labelTransKey: 'label.select_dates',
            mapOptions: true,
            required: true,
          }"
          @input="selectDate"
        />
      </q-slide-transition>
      <q-select
        v-model="typeControl"
        :options="reasons"
        :label="trans('attendance.timesheet-absent-reason')"
        :disable="confirming || reloading"
        option-value="value"
        option-label="label"
        required
      />
      <q-slide-transition>
        <div
          v-if="typeControl.value === 1"
          class="mb-10"
        >
          <q-input
            v-model="otherControl"
            :error="hasValidateFail('other')"
            :error-message="fails.other"
            :disable="confirming || reloading"
            required
            :label="trans('attendance.timesheet-absent-other')"
          />
        </div>
      </q-slide-transition>

      <q-slide-transition>
        <div
          v-if="![0,7].includes(typeControl.value)"
          class="mb-10"
        >
          <div class="sn-field__label">
            {{ trans('attendance.timesheet-absent-file') }}:
            <i v-if="typeControl.value !== 5 && typeControl.value !== 6">*</i>
          </div>
          <q-btn
            v-if="!reasonsFile"
            class="s-w-100 form-upload__button"
            icon="clip"
            color="blue"
            type="button"
            :label="trans('button.overview')"
            label-wider
            :loading="confirming || reloading"
            @click="triggerFile"
          />
          <q-btn
            v-if="reasonsFile"
            v-model="reasonsFile"
            icon="close"
            class="s-w-100 s-btn--border sn-btn--ellipsis"
            :label="reasonsFile.name"
            label-wider
            no-caps
            type="button"
            :loading="confirming || reloading"
            @click="clearFile"
          />
          <div
            v-if="hasValidateFail('file')"
            class="s-form__error-message mt-5"
          >
            {{ fails.file }}
          </div>
        </div>
        <!--suppress JSUnresolvedFunction -->
      </q-slide-transition>

      <input
        ref="fileInput"
        class="d-none"
        type="file"
        accept="image/*,.pdf"
        @change="fileChange"
      >
      <q-slide-transition>
        <div
          v-if="modalType === 1"
          class="row mb-20"
        >
          <q-slide-transition
            v-if="selectedDate"
            class="col-12"
          >
            <div>
              <q-field
                :label="trans('attendance.timesheet-load-child')"
                borderless
              >
                <div class="row justify-content-between">
                  <div class="col-12 col-sm-auto mb-10">
                    <q-btn
                      class="s-w-100"
                      width="20"
                      color="blue"
                      :label="trans('button.select-all')"
                      :loading="confirming || reloading"
                      @click="selectAllChild"
                    />
                  </div>
                  <div class="col-12 col-sm-auto">
                    <q-btn
                      class="s-w-100"
                      width="20"
                      color="orange"
                      :label="trans('button.un-select-all')"
                      :loading="confirming || reloading"
                      @click="unselectAllChild"
                    />
                  </div>
                </div>
              </q-field>
              <q-option-group
                v-if="editableChildren.length"
                v-model="sendAttendances"
                :options="editableChildren"
                type="checkbox"
              />
              <v-form-inform
                v-if="!editableChildren.length"
                :message="trans('attendance.timesheet-load-no-child')"
              />
            </div>
          </q-slide-transition>
        </div>
      </q-slide-transition>
      <div class="row justify-content-between">
        <div class="col-12 col-sm-auto mb-10">
          <q-btn
            class="s-w-100"
            width="20"
            label-wider
            color="grey"
            :label="trans('button.cancel')"
            :loading="confirming || reloading"
            @click="closeModal"
          />
        </div>
        <div class="col-12 col-sm-auto">
          <q-btn
            class="s-w-100"
            width="20"
            color="green"
            :disable="!isValid"
            :loading="confirming || reloading"
            @click="confirm"
          >
            {{ trans('button.approve') }}
          </q-btn>
        </div>
      </div>
    </v-modal>
  </q-dialog>
</template>

<script>
// noinspection NpmUsedModulesInstalled
import {
  QBtn,
  QDialog,
  QField,
  QInput,
  QOptionGroup,
  QSelect,
  QSlideTransition,
  QCheckbox,
} from '@quasar/components';
import VModal from '@vjs/components/VModal';
import VFormInform from '@vjs/components/VFormInform';
import Localization from '../../Mixins/QuasarLocale';

export default {
  name: 'VTimeSheetMassLoadDocDialog',
  components: {
    QCheckbox,
    QBtn,
    QDialog,
    VModal,
    QSelect,
    QSlideTransition,
    QInput,
    QField,
    QOptionGroup,
    VFormInform,
  },
  mixins: [Localization],
  props: {
    reasons: {
      type: Array,
      default: () => ([]),
    },
    apiProvider: {
      type: Object,
      default: () => {
      },
    },
    reloading: {
      type: Boolean,
      default: () => false,
    },
    isOpen: {
      type: Boolean,
      default: () => false,
    },
    isAlt: {
      type: Boolean,
      default: () => false,
    },
    massEditType: {
      type: Number,
      default: () => 1,
    },
    allowedEditDays: {
      type: Array,
      default: () => ([]),
    },
    attendances: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    const { reasons } = this;
    const reason = reasons.reduce((acc, item) => (item.value === 0 ? item : acc), null);
    return {
      selectedDate: null,
      rawType: reason,
      type: reason.value,
      reasonsFile: null,
      other: '',
      sendAttendances: [],
      fails: {},
      confirming: null,
      modalType: 1,
      altSelectedChild: null,
      altSelectedDates: [],
      allDates: false,
    };
  },
  computed: {
    modalTypeOptions() {
      return [
        { value: 1, label: this.trans('attendance.mass_docs_load_type_contingent') },
        { value: 2, label: this.trans('attendance.mass_docs_load_type_dates') },
      ];
    },
    isValid() {
      if (this.modalType === 1 && (!this.selectedDate || !this.sendAttendances.length)) {
        return false;
      }

      if (this.modalType === 2 && (!this.altSelectedChild || !this.altSelectedDates.length)) {
        return false;
      }

      switch (this.type) {
        case 0:
          return true;
        case 1:
          return !!this.otherControl && (this.reasonsFile !== null);
        case 5:
          return true;
        case 6:
          return true;
        case 7:
          return false;
        default:
          return (this.reasonsFile !== null);
      }
    },
    reasonsTypeList() {
      const { reasons } = this;
      return reasons.map(reason => reason.value);
    },
    editableDays() {
      const { attendances, allowedEditDays } = this;
      const absentDays = [];
      if (attendances.length) {
        attendances.map((item) => {
          const { days } = item;
          if (typeof days === 'object') {
            Object.keys(days)
              .map((key) => {
                const day = days[key];
                if (
                  typeof day.attendance !== 'undefined'
                  && typeof day.attendance.attendance !== 'undefined'
                  && day.attendance.attendance === 0
                  && !day.attendance.reviewed
                  && !day.attendance['has-file']
                ) {
                  absentDays.push(key);
                }
                return null;
              });
          }
          return null;
        });
      }

      return _.sortBy(_.uniq(absentDays.filter(item => allowedEditDays.indexOf(item) !== -1)));
    },
    editableChildrenAlt() {
      if ((this.massEditType !== 2 && this.massEditType !== 4) || this.modalType !== 2) {
        return [];
      }
      const { attendances } = this;
      const result = [];
      const filteredAttendances = attendances.filter(item => item.absentDays > 0);
      _.forEach(filteredAttendances, (item) => {
        const filteredDays = this.filterAttendanceDaysForAlt(item);
        if (!_.isEmpty(filteredDays)) {
          result.push({ label: item.full_name, value: item.child_id, days: filteredDays });
        }
      });

      return result;
    },
    editableDaysAlt() {
      const {
        editableChildrenAlt, altSelectedChild, massEditType, modalType,
      } = this;
      if ((massEditType !== 3 && massEditType !== 4) || modalType !== 2 || altSelectedChild === null) {
        return [];
      }
      const result = [];
      const selectedAttendance = editableChildrenAlt.find(itm => itm.value === altSelectedChild);
      _.forEach(selectedAttendance.days, (day, key) => {
        result.push({ value: day.attendance.id, label: key });
      });

      return result;
    },
    editableChildren() {
      const { attendances, selectedDate } = this;

      if (!selectedDate) {
        return [];
      }

      const selectAttendance = [];
      attendances.map((item) => {
        const { days } = item;
        if (typeof days === 'object') {
          Object.keys(days)
            .map((key) => {
              const day = days[key];
              if (
                key === selectedDate
                && typeof day.attendance !== 'undefined'
                && typeof day.attendance.attendance !== 'undefined'
                && day.attendance.attendance === 0
                && !day.attendance.reviewed
                && !day.attendance['has-file']
              ) {
                selectAttendance.push({
                  label: item.full_name,
                  value: day.attendance.id,
                });
              }
              return null;
            });
        }
        return null;
      });

      return selectAttendance;
    },
    typeControl: {
      get() {
        return this.rawType;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          // eslint-disable-next-line prefer-destructuring
          this.rawType = value;
          this.type = value.value;
        }

        if (value.value !== 1) {
          this.other = '';
        }

        this.fails.type = null;
        this.hasValidateFail('type');
      },
    },
    otherControl: {
      get() {
        return this.other;
      },
      set(value) {
        if (typeof value !== 'undefined') {
          this.other = value;
        }

        this.fails.other = null;
        this.hasValidateFail('other');
      },
    },
  },
  beforeCreate() {
    this.$trans.add(['label', 'attendance', 'button']);
  },
  methods: {
    filterAttendanceDaysForAlt(attendance) {
      const result = {};
      _.forEach(attendance.days, (day, key) => {
        if (day.attendance.attendance === 0 && day.attendance['has-file'] === false && !day.attendance.reviewed) {
          result[key] = day;
        }
      });
      return result;
    },
    clearModel() {
      const { reasons } = this;
      const reason = reasons.reduce((acc, item) => (item.value === 0 ? item : acc), null);
      this.selectedDate = null;
      this.reasonsFile = null;
      this.altSelectedChild = null;
      this.altSelectedDates = [];
      this.other = '';
      this.sendAttendances = [];
      this.rawType = reason;
      this.type = reason.value;
    },
    closeModal() {
      this.$emit('modal-close', false);
      this.clearModel();
    },
    hasValidateFail(field) {
      const {
        type, other,
        fails,
      } = this;

      if (this.reloading) {
        return null;
      }

      if (
        typeof fails[field] !== 'undefined'
        && fails[field] !== null
      ) {
        return true;
      }
      if (field === 'type') {
        this.fails.type = null;
        if (type !== 1) {
          if (this.reasonsTypeList.indexOf(type) === -1) {
            this.fails.type = 'invalid type';
          }
        } else if (this.reasonsTypeList.indexOf(type) === -1) {
          this.fails.type = 'invalid type';
          return true;
        } else if (other && other.length === 0) {
          this.fails.type = 'invalid type';
          return true;
        }
        return null;
      }
      return null;
    },
    clearFile() {
      this.reasonsFile = null;
    },
    triggerFile() {
      this.$refs.fileInput.click();
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.reasonsFile = file;
      } else {
        this.reasonsFile = null;
        this.$refs.fileInput.value = null;
      }
    },
    selectAllChild() {
      this.sendAttendances = this.editableChildren.map(item => item.value);
    },
    unselectAllChild() {
      this.sendAttendances = [];
    },
    selectDate() {
      this.allDates = this.altSelectedDates.length === this.editableDaysAlt.length;
    },
    selectAllDates() {
      this.altSelectedDates = this.allDates ? this.editableDaysAlt : [];
    },
    async confirm() {
      const {
        type,
        other,
        reasonsFile,
        sendAttendances,
        altSelectedDates,
        apiProvider,
        $trans: { get: trans },
      } = this;
      this.serverFail = null;
      this.confirming = true;

      const reason = {
        type,
        other,
        file: reasonsFile,
      };

      let attendances = sendAttendances;
      if (this.modalType === 2) {
        attendances = _.map(altSelectedDates, item => item.value);
      }

      const response = await apiProvider.provideMassMark()
        .apply(this, [reason, attendances]);

      this.confirming = false;

      if (response.responseStatus === 'validation-fail') {
        this.serverFail = true;
        Object.keys(response.data)
          .forEach((key) => {
            this.fails[key] = response.data[key];
          });
      }

      if (response.responseStatus === 'ok') {
        this.$emit('modal-reload', false);
        this.$notify({
          type: 'success',
          text: trans('attendance.timesheet-confirm-text'),
        });
      }
    },
  },
};
</script>

<style scoped>

</style>
