import _ from 'lodash';
import { Helper } from '@common/src/helpers';
import VueSModal from './SModal.vue';

const SModal = {
  install(_Vue) {
    const modal = new _Vue({
      name: 'SModals',
      data: {
        modals: {},
        checkModal: {},
      },
      created() {
      },

      methods: {
        open(id, options) {
          if (_.get(options, 'preCheck', null) === null) {
            this.$set(this.modals, id, options);
          } else {
            this.checkModal = { id, options };
            this.checkAction(options.preCheck);
          }
        },
        async close(id) {
          const item = this.modals[id];
          if (item.preClose) {
            await item.preClose();
          }
          this.$delete(this.modals, id);
        },
        closeAll() {
          this.modals = {};
        },
        checkAction(preCheck) {
          const vm = this;
          const data = {};

          const { action } = preCheck;
          let { method } = preCheck;

          if (!method) {
            method = 'post';
          }
          vm.$globalLoading.show();

          const config = {
            responseType: 'json',
            method,
            headers: {
              'X-CHECK-ACTION': '1',
            },
          };
          config.headers['X-CSRF-TOKEN'] = window.core_project.csrfToken;

          let request;
          const formData = Helper.genFormDataArray(data);
          switch (method.toLowerCase()) {
            default:
            case 'post':
              request = vm.$http.post(action, formData, config);
              break;
            case 'delete':
              formData.append('_method', 'DELETE');
              request = vm.$http.post(action, formData, config);
              break;
            case 'patch':
              formData.append('_method', 'patch');
              request = vm.$http.post(action, formData, config);
              break;
          }
          request.then(
            (response) => {
              if (Helper.handlerResponseError(response)) {
                vm.$globalLoading.hide();
                if (response.status === 200) {
                  vm.$set(vm.modals, vm.checkModal.id, vm.checkModal.options);
                }
              }
            },
            (response) => {
              const responseData = response?.body?.data || response?.data || {};
              const { warning, notice } = responseData;

              if (warning) {
                vm.$notify({ text: warning, type: 'warn' });
              } else if (notice) {
                vm.$notify({ text: notice, type: 'success' });
              } else {
                Helper.handlerResponseError(response);
              }

              vm.$globalLoading.hide();
            },
          );
        },
      },
    });

    const handleBinding = (el, binding) => {
      const config = binding.value;
      let id = '';
      let preCheck = null;
      let component = '';
      let title = '';
      let size = '480';// 480 700 800
      let inBody = true;
      let notPersistent = false;
      let binds = {};
      let events = {};
      if (_.isString(config)) {
        id = config;
        component = config;
      } else {
        binds = config.binds || {};
        events = config.events || {};
        // eslint-disable-next-line prefer-destructuring
        id = config.id;
        // eslint-disable-next-line prefer-destructuring
        component = config.component;
        // eslint-disable-next-line prefer-destructuring
        title = config.title;
        // eslint-disable-next-line prefer-destructuring
        inBody = config.inBody;
        // eslint-disable-next-line prefer-destructuring
        notPersistent = config.notPersistent;

        preCheck = config.preCheck ?? preCheck;
        size = config.size ? String(config.size) : size;
      }

      el.addEventListener('click', (event) => {
        event.preventDefault();
        modal.open(id, {
          size,
          title,
          inBody,
          notPersistent,
          preCheck,
          component,
          binds,
          events,
        });
      });
    };

    // eslint-disable-next-line no-param-reassign
    _Vue.prototype.$sModal = modal;
    // eslint-disable-next-line no-param-reassign
    _Vue.sModal = modal;
    _Vue.component('s-modals', VueSModal);
    _Vue.directive('s-modal', {
      bind(el, binding) {
        if (binding.value) {
          handleBinding(el, binding);
        }
      },
      update(el, binding) {
        if (binding.value) {
          handleBinding(el, binding);
        }
      },
    });
  },
};

export default SModal;
