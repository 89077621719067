<template>
  <div class="s-pos-relative-position s-voucher-queue__items">
    <div
      class="s-title-link s-medium s-c-blue"
      :class="{'s-title-link--small': $q.screen.width < 768}"
    >
      <div
        class="s-title-link__link"
        @click="$emit('handleBackBtn')"
      >
        <q-icon
          name="arrow-left"
          icon-class="s-ico-fa"
        />
        <q-tooltip>{{ trans('button.back') }}</q-tooltip>
      </div>
      {{ categoryName }}
    </div>
    <div class="row">
      <div
        :class="{
          'col-lg-5': !isSpecial,
          'col-lg-8': isSpecial,
          'col-md-7': !isSpecial,
          'col-md-12': isSpecial,
        }"
        class="col-sm-12"
      >
        <q-input
          v-model="filters.search"
          class="s-voucher-queue__items-input"
          :debounce="300"
          :placeholder="trans('placeholder.search')"
          :label="trans('label.search_by_keyword')"
        >
          <template
            v-if="$q.screen.width > 400"
            v-slot:prepend
          >
            <div class="s-c-white s-b-blue">
              <q-icon
                name="search"
                size="2rem"
              />
            </div>
          </template>
        </q-input>
      </div>
      <div
        v-if="withStopList"
        class="col-lg-4 col-md-5 col-sm-12 align-content-center mt-25"
      >
        <q-btn
          no-caps
          width="26"
          :outline="!hideStopList"
          color="orange"
          class="s-w-100"
          :label="trans(`queue.voucher_stop_list_${hideStopList ? 'show' : 'hide'}`)"
          @click="hideStopList = !hideStopList"
        />
      </div>
    </div>
    <div class="row">
      <div
        v-if="refsComputed.years"
        class="col-lg-4 col-md-4 col-sm-12"
      >
        <v-wrapper-q-select
          v-model="filters.year"
          :options="refsComputed.years"
          :extra-binds="{
            label: trans('queue.child_birth_age'),
            hideBottomSpace: true,
            required: true,
          }"
        />
      </div>
      <div
        v-if="refsComputed.langs"
        class="col-lg-4 col-md-4 col-sm-12"
      >
        <v-wrapper-q-select
          v-model="filters.lang"
          :options="refsComputed.langs"
          :extra-binds="{
            label: trans('queue.language'),
            hideBottomSpace: true,
            required: true,
          }"
        />
      </div>
      <div
        v-if="refsComputed.organizations"
        class="col-lg-4 col-md-4 col-sm-12"
      >
        <v-wrapper-q-select
          v-model="filters.org"
          :options="refsComputed.organizations"
          :extra-binds="{
            label: trans('queue.filter_organization'),
            hideBottomSpace: true,
            required: true,
          }"
        />
      </div>
    </div>

    <div class="s-voucher-queue__items-wrapper mt-30">
      <v-voucher-queue-item
        v-for="(item, key) in paginate"
        :key="key"
        :item="item"
        :is-adaptive="isAdaptive"
      />
    </div>
    <div
      v-if="countOfPages"
      class="row justify-content-center mt-30"
    >
      <div class="col-auto">
        <q-pagination
          v-model="currentPage"
          :max="countOfPages"
          :max-pages="5"
          boundary-links
          :input="countOfPages > 5"
          input-class="s-c-blue"
          @input="$emit('scrollTop')"
        />
      </div>
    </div>
    <v-no-results
      v-if="empty"
      image="queue"
      :title="trans('warning_text.no_result_queue_voucher_title')"
      :text="trans('warning_text.no_result_queue_voucher_text', {url: uriSendStatement})"
    />
  </div>
</template>

<script>
import { QIcon, QPagination } from '@quasar/components';
import { genArray } from '@vjs/helpers';
import VNoResults from '@vjs/components/VNoResults';
import VVoucherQueueItem from './VVoucherQueueItem';

export default {
  name: 'VVoucherQueueCategory',
  components: {
    QIcon,
    QPagination,
    VVoucherQueueItem,
    VNoResults,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
    refs: {
      type: Object,
      default: () => ({
        organizations: [],
      }),
    },
    withStopList: Boolean,
    isSpecial: Boolean,
    categoryName: String,
    uriSendStatement: String,
    isAdaptive: Boolean,
  },
  data() {
    return {
      currentPage: 1,
      countByPage: 10,
      hideStopList: false,
      filters: {
        year: window.currentDayHelper.year,
        search: '',
        lang: 'kz',
        org: this.refs.organizations[0]?.id,
      },
    };
  },
  computed: {
    refsComputed() {
      if (!this.isSpecial) {
        return {};
      }
      const langs = [
        { id: 'kz', name: this.trans('queue.lang_kz') },
        { id: 'ru', name: this.trans('queue.lang_ru') },
      ];
      const years = [];
      const makeOpt = (val) => { years.push({ id: val, name: val }); };
      const { year } = window.currentDayHelper;
      makeOpt(year);
      _.forEach(Array(6), (itm, key) => {
        makeOpt(year - key - 1);
      });

      return { langs, years, ...this.refs };
    },
    empty() {
      return this.itemsFiltered.length === 0 && !this.loading;
    },
    // Отдаёт кол-во очереди на страницу
    paginate() {
      if (this.loading) {
        return genArray({
          childFullName: '',
          dateSendStatement: 0,
          expiredDate: 0,
          privilegeClient: {
            key: 1,
            value: '',
          },
          queueFlagStyle: '',
          queueNumber: 0,
          userFullName: '',
        }, 4);
      }

      const current = (this.currentPage - 1) * this.countByPage;
      return this.itemsFiltered.slice(current, current + this.countByPage);
    },
    // Считает кол-во страниц в пагинации по выставленным в data параметрам
    countOfPages() {
      let count = parseInt(this.itemsFiltered.length / this.countByPage, 10);
      const remains = this.itemsFiltered.length % this.countByPage;
      count += remains !== 0 ? 1 : 0;
      this.currentPage = 1;
      return count <= 1 ? 0 : count;
    },
    itemsFiltered() {
      const {
        search, year, lang, org,
      } = this.filters;
      let items = _.cloneDeep(this.items);

      if (search !== '') {
        const searchStr = search.toLowerCase();
        items = items.filter((a) => {
          const childName = a.childName || '';
          const parentName = a.parentName || '';
          return childName.toLowerCase().indexOf(searchStr) !== -1
            || parentName.toLowerCase().indexOf(searchStr) !== -1;
        });
      }

      if (this.isSpecial) {
        if (year) {
          items = items.filter(itm => itm.birthYear === year);
        }
        if (lang) {
          items = items.filter(itm => itm.lang === lang);
        }
        if (org) {
          items = items.filter(itm => itm.orgId === org);
        }
      }

      if (this.hideStopList && this.withStopList) {
        items = items.filter(itm => itm.color !== 'red');
      }

      return items;
    },
  },
};

</script>
