<template>
  <div
    class="faq-answear"
    :class="{ 'is-active': show }"
  >
    <div
      class="faq-answear__head"
      @click="show = !show"
    >
      <div class="faq-answear__title">
        {{ title }}
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="show"
        class="faq-answear__body"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style lang="scss" scoped>
.faq-answear {
  box-shadow: unset;
  border: 1px solid rgba(236, 238, 245, 1);
  margin-bottom: 2rem;

  &__title {
    text-transform: uppercase;
  }

  &__head {
    background-color: rgba(242, 242, 242, 1) !important;
    color: #000000 !important;

    &::after {
      color: #000000 !important;
    }
  }

  &__body {
    padding: 2rem;
  }
}
</style>

<script>
export default {
  name: 'VSpoiler',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    };
  },
};

</script>
