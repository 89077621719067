import VEditSchoolLimitDialog from '@vjs/balabaqsha/modals/VEditSchoolLimitDialog';
import VEditGroupLimitDialog from '@vjs/balabaqsha/modals/VEditGroupLimitDialog';
import VParentPaymentSubmitSignModal from '@vjs/balabaqsha/views/ParentPayment/VParentPaymentSubmitSignModal';
import VNewProductInventoryDialog from '@vjs/balabaqsha/modals/Food/VNewProductInventoryDialog';
import VAddProductQualityCertificate from '@vjs/balabaqsha/modals/Food/VAddProductQualityCertificateDialog';
import VUploadFileModal from '@vjs/balabaqsha/modals/VUploadFIleModal';
import VEditStatementSchoolsModal from '@vjs/balabaqsha/modals/VEditStatementSchoolsModal';
import FoodRefuseModal from '@vjs/balabaqsha/modals/Food/FoodRefuseModal';
import VStatementContractExtendModal from '@vjs/balabaqsha/views/VStatementContractShow/VStatementContractExtendModal';
import VParentPaymentUploadFileModal from '@vjs/balabaqsha/views/ParentPayment/VParentPaymentUploadFileModal';
import VFundingDocumentsReasonModal from '@vjs/balabaqsha/views/FundingDocuments/VFundingDocumentsReasonModal';
import EgovSign from '@common/components/Modals/EgovSign/EgovSign';
import VoucherFunding from './VoucherFunding';
import Confirm from './Confirm';
import ConfirmWithMessage from './ConfirmWithMessage';
import FoodInfo from './FoodInfo';
import PersonnelTerminationModal from './PersonnelTerminationModal';
import SelectMapOrg from './SelectMapOrg';
import Inform from './Inform';
import InformCritical from './InformCritical';
import UserAdd from './UserAdd';
import CsiQuiz from './CsiQuiz';
import StatementLgots from './StatementLgots';
import AddContingent from './AddContingent';
import ConfirmCustomActions from './ConfirmCustomActions';

export default function (Vue) {
  VoucherFunding(Vue);
  Vue.component('v-edit-school-limit-dialog', VEditSchoolLimitDialog);
  Vue.component('v-edit-group-limit-dialog', VEditGroupLimitDialog);
  Vue.component('v-modal-confirm', Confirm);
  Vue.component('v-modal-confirm-with-message', ConfirmWithMessage);
  Vue.component('v-modal-food-info', FoodInfo);
  Vue.component('v-modal-personnel-termination', PersonnelTerminationModal);
  Vue.component('v-parent-payment-submit-sign-modal', VParentPaymentSubmitSignModal);
  Vue.component('v-modal-food-refuse', FoodRefuseModal);
  Vue.component('v-new-product-inventory-dialog', VNewProductInventoryDialog);
  Vue.component('v-add-product-quality-certificate-dialog', VAddProductQualityCertificate);
  Vue.component('v-statement-contract-extend-modal', VStatementContractExtendModal);
  Vue.component('v-parent-payment-upload-file-modal', VParentPaymentUploadFileModal);
  Vue.component('v-funding-documents-reason-modal', VFundingDocumentsReasonModal);
  Vue.component('v-upload-file-modal', VUploadFileModal);
  Vue.component('v-edit-statement-schools-modal', VEditStatementSchoolsModal);
  Vue.component('v-modal-select-map-org', SelectMapOrg);
  Vue.component('v-modal-egov-sign', EgovSign);
  Vue.component('v-modal-inform', Inform);
  Vue.component('v-modal-inform-critical', InformCritical);
  Vue.component('v-modal-user-add', UserAdd);
  Vue.component('v-modal-csi-quiz', CsiQuiz);
  Vue.component('v-modal-statement-lgots', StatementLgots);
  Vue.component('v-modal-add-contingent', AddContingent);
  Vue.component('v-modal-confirm-custom-actions', ConfirmCustomActions);
}
