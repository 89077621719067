<template>
  <v-spoiler :title="trans('fc_reports.desc')">
    <div>
      <div
        v-for="(itm, key) in item"
        :key="key"
        class="d-flex"
        :class="{
          ['mb-5']: $q.screen.width < 500,
          ['flex-column']: $q.screen.width < 500,
        }"
      >
        <div
          class="s-c-blue"
          :style="$q.screen.width > 499 ? 'margin-right: 0.5rem;' : ''"
        >
          {{ itm.label }}:
        </div>
        <div
          class="s-c-blue s-medium"
          style="min-width: 10rem"
        >
          {{ itm.value }}
        </div>
      </div>
    </div>
  </v-spoiler>
</template>

<script>
import VSpoiler from '@common/components/VSpoiler';

export default {
  name: 'VVoucherFcReportDesc',
  components: { VSpoiler },
  props: {
    item: {
      type: Array,
      required: true,
    },
  },
};

</script>
