<template>
  <v-spoiler
    class="s-table-with-filters--without-hover"
    :title="trans('fc_reports.calc_table')"
  >
    <q-table
      :data="item.data"
      :columns="item.cols[item.cols.length-1]"
      flat
      separator="cell"
      class="s-b-white"
      wrap-cells
      style="max-width: 92rem"
      hide-bottom
    >
      <template v-slot:header="props">
        <q-tr
          v-for="(row, rowKey) in item.cols.slice(0,-1)"
          :key="rowKey"
          :props="props"
        >
          <q-th
            v-for="(col, colKey) in row"
            :key="`${rowKey}-${colKey}`"
            :rowspan="col.rowspan"
            :colspan="col.colspan"
            :align="col.align"
            :style="{width: `${col.width}px`}"
            :props="customHeaderProps[rowKey]"
          >
            {{ col.label }}
          </q-th>
        </q-tr>
      </template>
      <template v-slot:body="props">
        <q-tr :props="props">
          <template v-for="(col,i) in props.cols">
            <q-td
              v-if="![null, undefined].includes(col.value)"
              :key="i"
              :style="col.value?.customStyle"
              :rowspan="col.value?.rowspan"
              :colspan="col.value?.colspan"
              :align="col.align"
              v-html="col.value?.value ?? col.value"
            />
          </template>
        </q-tr>
      </template>
    </q-table>
  </v-spoiler>
</template>

<script>
import {
  QTable, QTd, QTh, QTr,
} from '@quasar/components';
import VSpoiler from '@common/components/VSpoiler';

export default {
  name: 'VVoucherFcReportCalcTable',
  components: {
    QTh, QTd, QTable, QTr, VSpoiler,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    customHeaderProps() {
      const result = [];
      _.forEach(this.item.cols, (row, rowKey) => {
        const colsMap = {};
        const cols = [];
        _.forEach(row, (itm, key) => {
          colsMap[`${rowKey}-${key}`] = { ...itm, field: itm.name };
          cols[key] = { ...itm, field: itm.name };
        });
        result.push({ header: true, cols, colsMap });
      });
      return result;
    },
  },
};

</script>
