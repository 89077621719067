import {
  QBtn,
  QCheckbox,
  QField,
  QForm,
  QInput,
  QOptionGroup,
  QSelect,
  QSlideTransition,
} from '@quasar/components';
import VReferenceTime from '@vjs/components/VReferenceTime';
import { requestWrapper, url } from '@vjs/helpers';
import Validation from '@vjs/mixins/Validation';
import VFormInform from '@vjs/components/VFormInform';
import _ from 'lodash';
import VStatementQuestionnaire
from '@vjs/balabaqsha/components/VStatementQuestionnaire';
import { Helper } from '@common/src/helpers';


export default {
  name: 'VRegularStatementCreateView',
  mixins: [Validation],
  components: {
    VStatementQuestionnaire,
    QInput,
    QBtn,
    QCheckbox,
    QOptionGroup,
    QSelect,
    QForm,
    QField,
    VReferenceTime,
    QSlideTransition,
    VFormInform,
  },
  props: {
    referenceTime: {
      type: Number,
      required: true,
    },
    uriChildren: {
      type: String,
      default: '/api/v1/user/statements/get/children',
    },
    uriCheckChild: {
      type: String,
      default: '',
    },
    uriChildCreate: {
      type: String,
      required: true,
    },
    uriLanguages: {
      type: String,
      default: '/api/v1/references/statement/languages',
    },
    uriBenefits: {
      type: String,
      default: '/api/v1/references/statement/benefits',
    },
    uriGroupTypes: {
      type: String,
      default: '/api/v1/references/statement/group-types',
    },
    uriSubmit: {
      type: String,
      required: true,
    },
    uriSubmitDisease: {
      type: String,
      required: true,
    },
    uriSubmitSanatorium: {
      type: String,
      required: true,
    },
    uriCheckBenefits: {
      type: String,
    },
    uriCheckBenefitsNedb: {
      type: String,
    },
    uriGetLgotTypes: {
      type: String,
    },
    uriGetLgotTypeDocuments: {
      type: String,
    },
    uriCheckSpecialType: {
      type: String,
    },
    uriSchoolsInMap: {
      type: String,
    },
    regions: {
      type: String,
      default: 'api/v1/regions',
    },
    departments: {
      type: String,
      default: 'api/v1/department',
    },
    localities: {
      type: String,
      default: 'api/v1/active-localities',
    },
    organizations: {
      type: String,
      default: 'api/v1/statement-organizations',
    },
    statusOptions: {
      type: Array,
      required: true,
    },
    uriGetStatementFormData: {
      type: String,
      required: true,
    },
    diseaseTypes: {
      type: Array,
      required: true,
    },
    sanatoriumTypes: {
      type: Array,
      required: true,
    },
    queueTypes: {
      type: Array,
      required: true,
    },
    questionnaire: {
      type: Object,
      default: () => ({
        enabled: false,
        getDataUrl: null,
        getSchoolsUrl: null,
        submitUrl: null,
        parentInfo: {
          iin: null,
          phone: null,
          mail: null,
        },
      }),
    },
    nedbIntegration: {
      type: Boolean,
      default: false,
    },
    fromStatementCreateView: {
      type: Boolean,
      default: false,
    },
    child: {
      type: Number,
      default: null,
    },
    setData: {
      type: Object,
      default: () => ({}),
    },
    setDepartmentData: {
      type: Object,
      default: () => ({}),
    },
    isVoucherStatement: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    if (this.fromStatementCreateView) {
      this.$eventHub.$on('SET_NEXT_STEP', this.nextStep);
    }
  },
  watch: {
    validate(val) {
      this.$store.commit('SET_ACCESS_STEP', {
        step: 5,
        access: val,
      });
    },
  },
  data() {
    return {
      license: false,
      licenseBmg: false,
      licenseGov: false,
      children: [],
      benefits: [],
      languages: [],
      groupTypes: [],
      groupTypeOptions: [],
      langOptions: [],
      statement: {
        child: this.child,
        locality_id: null,
        curRegion: null,
        curDep: {
          nedbIntegration: this.nedbIntegration,
        },
        languages: [],
        correction: null,
        sanatorium: null,
        type: null,
        soc_status_id: 0,
        lgotType: {
          value: 0,
          label: this.trans('statement.socStatuses.no_lgot'),
        },
        queueType: 0,
        firstSchool: {
          id: null,
          lang: null,
          langs: null,
        },
        secondSchool: {
          id: null,
          lang: null,
          langs: null,
        },
        thirdSchool: {
          id: null,
          lang: null,
          langs: null,
        },
        fourthSchool: {
          id: null,
          lang: null,
          langs: null,
        },
        lgotTypeOptions: [],
        lgotTypeDocuments: {},
        lgotTypeDocumentRules: [],
        statementDocumentDisease: null,
        sanatoriumDocumentDisease: null,
        nedbCategory: null,
        nedbConsultationDate: {
          enabled: false,
          value: null,
        },
        nedbConclusion: {
          enabled: false,
          value: null,
        },
        nedbRecommendation: {
          enabled: false,
          value: null,
        },
        nedbConclusionNumber: {
          enabled: false,
          value: null,
        },
        needDiseaseDocument: false,
        zagsDocument: null,
      },
      showIgnoreRshepSocStatus: false,
      showNedbSpecialTypes: false,
      nedbSpecialTypesRequired: false,
      ignoreRshepSocStatus: false,
      current: null,
      curLoc: null,
      curOrg: null,
      nedbCategories: [],
      optionsRegions: [],
      optionsDepartments: [],
      optionsLocalities: [],
      optionsOrganization: [],
      activeFile: null,
      activeLgotFile: null,
      hasOrgQueue: false,
      hasCorrOrgQueue: false,
      questionnaireShow: false,
      socialStatusExist: false,
      questionnaireData: {},
    };
  },
  async mounted() {
    await this.$trans.add([
      'statement',
      'queue',
      'button',
      'warning_text',
      'main',
      'label',
      'notifications',
    ]);

    this.$globalLoading.show();

    await this.loadChildren();

    if (this.fromStatementCreateView) {
      await this.checkBenefits();
    }

    const languagesRes = await requestWrapper({
      url: this.uriLanguages,
    });
    this.languages = languagesRes.data;

    const benefitsRes = await requestWrapper({
      url: this.uriBenefits,
    });
    this.benefits = benefitsRes.data;

    const groupTypesRes = await requestWrapper({
      url: this.uriGroupTypes,
    });
    this.groupTypes = groupTypesRes.data;

    const formData = await requestWrapper.call(this, {
      url: this.uriGetStatementFormData,
    });
    if (!formData.error) {
      this.groupTypeOptions = formData.groupType;
      this.statement.type = this.groupTypes[0]?.value;
      this.langOptions = formData.langs;
    }
    this.statement.lgotType = {
      value: 0,
      label: this.trans('statement.socStatuses.no_lgot'),
    };

    if (this.setData && Object.keys(this.setData).length > 0) {
      await this.initSetData();
    }

    this.$globalLoading.hide();
  },
  computed: {
    uriSchoolsMap() {
      const params = new URLSearchParams({
        fromStatement: true,
        data: JSON.stringify(this.statement),
      }).toString();
      return `${this.uriSchoolsInMap}?${params}`;
    },
    getCorrection() {
      if (!this.statement.type) {
        return false;
      }
      if (this.statement.type === 3) {
        return false;
      }
      if (this.statement.correction) {
        return this.statement.correction;
      }
      return 1;
    },
    getSanatorium() {
      if (!this.statement.type) {
        return false;
      }
      if (this.statement.type === 3) {
        return this.statement.sanatorium ? this.statement.sanatorium : 1;
      }
      return false;
    },
    queueTypeOptions() {
      const { queueTypes, hasCorrOrgQueue } = this;
      const types = [];
      _.forEach(queueTypes, (item) => {
        types.push(item);
      });
      if (hasCorrOrgQueue === true && this.statement.type === 1) {
        types.pop();
      }
      return types;
    },
    validateChooseRegion() {
      return this.statement.curDep || this.curLoc;
    },
    validate() {
      let lgotDocumentsValidate = true;
      _.forEach(this.statement.lgotTypeDocuments, (file, key) => {
        if (this.statement.lgotTypeDocumentRules[key].required === true && file === null) {
          lgotDocumentsValidate = false;
        }
      });

      const license = this.fromStatementCreateView ? true
        : this.license !== false
        && (
          !this.statement.curDep?.nedbIntegration
          || (this.statement.curDep?.nedbIntegration && this.licenseBmg !== false
            && this.licenseGov !== false)
        );

      return (
        license
        && this.statement.child !== null
        && this.statement.type !== null
        && (this.statement.languages.length > 0 || this.statement.curDep?.nedbIntegration)
        && this.statement.curRegion
        && this.statement.curRegion.value !== null
        && this.statement.curDep
        && this.statement.curDep.value !== null
        && this.statement.locality_id !== null
        && (this.statement.queueType === 0
          || (this.statement.queueType !== null
            && this.statement.lgotType.value !== null
            && this.statement.lgotType.value !== 0
            && Object.keys(this.statement.lgotTypeDocuments).length > 0
            && lgotDocumentsValidate
          )
          || (
            this.statement.curDep?.nedbIntegration === true && this.statement.queueType === 2
            && (
              (this.statement.lgotType.value !== null && this.statement.lgotType.value !== 0)
              || this.statement.soc_status_id === 13
            )
          )
        )
      ) && (
        this.statement.type === 0
        || (
          (
            this.statement.type === 1
            && this.statement.statementDocumentDisease
            && this.statement.correction
            && this.hasCorrOrgQueue === false
          ) || (
            this.statement.type === 1
            && this.statement.statementDocumentDisease
            && this.statement.correction
            && this.hasCorrOrgQueue !== false
            && this.statement.firstSchool.id
          )
          || (
            this.statement.type === 1
            && this.statement.curDep.nedbIntegration
          )
        )
        || (
          (
            this.statement.type === 3
            && this.statement.sanatoriumDocumentDisease
            && this.statement.sanatorium
            && this.hasCorrOrgQueue === false
          ) || (
            this.statement.type === 3
            && this.statement.sanatoriumDocumentDisease
            && this.statement.sanatorium
            && this.hasCorrOrgQueue !== false
            && this.statement.firstSchool.id
          )
        )
      ) && (
        this.statement.curDep?.nedbIntegration == null || this.statement.curDep?.nedbIntegration === false
        || (
          this.statement.curDep?.nedbIntegration === true
          && (
            (this.statement.firstSchool.id != null && this.statement.firstSchool.lang != null)
            || (
              this.statement.secondSchool.id != null && this.statement.secondSchool.lang != null
            )
            || (
              this.statement.thirdSchool.id != null && this.statement.thirdSchool.lang != null
            )
            || (
              this.statement.fourthSchool.id != null && this.statement.fourthSchool.lang != null
            )
          )
        )
      );
    },
    canSelectSchools() {
      return this.optionsOrganization.length > 0
        && (
          (
            (this.getCorrection && this.hasCorrOrgQueue)
            || (this.getSanatorium && this.hasCorrOrgQueue)
            || this.hasOrgQueue
            || this.statement.curDep?.nedbIntegration === true
          )
          && this.statement.locality_id
        );
    },
    canSelectLgot() {
      return (
        this.hasValidDep()
        && this.hasValidSocStatus()
        && this.hasValidNedbIntegration()
        && this.hasValidVoucherType()
      );
    },
    isSpecialStatementType() {
      return [1, 3].indexOf(this.statement.type) !== -1;
    },
  },
  methods: {
    hasValidDep() {
      return this.statement.curDep?.value !== null;
    },
    hasValidSocStatus() {
      return this.statement.soc_status_id !== 13;
    },
    hasValidNedbIntegration() {
      return !this.isSpecialStatementType || !this.statement.curDep?.nedbIntegration;
    },
    hasValidVoucherType() {
      return !this.isVoucherStatement || this.statement.type !== 1;
    },
    async initSetData() {
      if (this.setData != null) {
        await this.request(this.regions);
        const tmpData = _.cloneDeep(this.setData);
        if (tmpData.curRegion) {
          this.$set(this.statement, 'curRegion', tmpData.curRegion);
          await this.checkOptions('curRegion', 'optionsDepartments', 'departments', 'region');
          delete tmpData.curRegion;
          if (tmpData.curDep) {
            this.$set(this.statement, 'curDep', tmpData.curDep);
            await this.checkOptions('curDep', 'optionsLocalities', 'localities', 'department');
            delete tmpData.curDep;
            if (tmpData.locality_id) {
              this.$set(this.statement, 'locality_id', tmpData.locality_id);
              await this.loadOrganizations(tmpData.locality_id, 'locality');
              delete tmpData.locality_id;
            }
          }
        }
        _.forEach(tmpData, (value, key) => {
          if (this.statement.hasOwnProperty(key)) {
            this.$set(this.statement, key, value);
          }
        });

        const initSchoolLang = (type) => {
          const school = this.statement[type];
          if (school.id) {
            const option = this.optionsOrganization.find(itm => itm.value === school.id);
            if (option) {
              school.langs = option.langs;
              this.statement[type] = school;
            }
          }
        };
        initSchoolLang('firstSchool');
        initSchoolLang('secondSchool');
        initSchoolLang('thirdSchool');
        initSchoolLang('fourthSchool');
        this.$forceUpdate();
      }
    },
    async request(address = '', params) {
      const options = {
        method: 'get',
        url: url(address),
      };

      if (params) {
        options.params = params;
      }


      const res = await requestWrapper.call(this, {
        ...options,
      });

      if (!res.error) {
        const { data } = res;
        return Array.isArray(data) ? data : [];
      }
      if (res.error) {
        return [];
      }
    },
    async filterRegions(val, update, abort) {
      if (this.optionsRegions.length > 0) {
        update();
        return;
      }

      try {
        this.optionsRegions = await this.request(this.regions);
        update();
      } catch {
        abort();
      }
    },
    async checkOptions(keyCur = '', keyOptions, keyRequest, keyParams) {
      if (this.statement[keyCur]
        && this.statement[keyCur].value
        && keyOptions
        && keyRequest
        && keyParams
      ) {
        this[keyOptions] = await this.request(this[keyRequest], {
          [keyParams]: this.statement[keyCur].value,
          fromStatementCreate: 1,
          statementType: this.statement.type,
        });
      }

      this.current = {
        keyParams,
        request: this[keyRequest],
        ...this[keyCur],
      };

      if (keyCur === 'curRegion') {
        this.optionsLocalities = [];
        this.statement.curDep = null;
        this.statement.locality_id = null;
      }

      if (keyCur === 'curDep') {
        if (this.statement.child != null) {
          await this.checkBenefits();
        }
        this.statement.locality_id = null;
        await this.clearLgots();
      }
      this.optionsOrganization = [];
    },
    async changeGroupType() {
      await this.loadChildren();
      await this.loadOrganizations();
      if (this.sanatoriumTypes.length) {
        this.statement.sanatorium = this.sanatoriumTypes[0].value;
      }
      if (this.statement.queueType > 0) {
        await this.loadLgotTypeOptions();
      }

      this.onLanguageChange();
    },
    async loadChildren() {
      const {
        getCorrection: correction,
        getSanatorium: sanatorium,
        statement: { child },
      } = this;
      this.statement.child = null;
      const childrenRes = await requestWrapper({
        url: this.uriChildren,
        params: {
          correction,
          sanatorium,
        },
      });
      this.children = childrenRes.data;
      if (child) {
        const found = _.find(childrenRes.data, { value: child });
        this.statement.child = found ? child : null;
      }
    },
    async loadOrganizations(val, type) {
      this.$globalLoading.show();
      this.clearSchool();
      if (val && type === 'locality') {
        this.hasOrgQueue = this.optionsLocalities.find(itm => itm.value === val)?.hasOrgQueue;
        this.hasCorrOrgQueue = this.optionsLocalities.find(itm => itm.value === val)?.hasCorrOrgQueue;
      }
      if (this.statement.locality_id) {
        const { statement: { firstSchool } } = this;
        this.statement.firstSchool.id = null;
        this.optionsOrganization = await this.request(this.organizations, {
          locality_id: this.statement.locality_id,
          correction: this.getCorrection,
          sanatorium: this.getSanatorium,
        });
        const found = _.find(this.optionsOrganization, { value: firstSchool.id });
        this.statement.firstSchool.id = found ? firstSchool.id : null;

        if (
          (this.getCorrection || this.getSanatorium)
          && (
            this.hasCorrOrgQueue
            || this.statement.curDep?.nedbIntegration
          )
          && this.optionsOrganization.length === 0
        ) {
          this.$notify({
            text: this.trans('notice.no_orgs_to_statement'),
            type: 'warn',
          });
        }
      }
      this.$globalLoading.hide();
    },
    // bala issue 1025
    async nextStep() {
      if (this.questionnaire.enabled && this.statement.curDep?.questionnaire) {
        if (!this.questionnaireShow) {
          this.questionnaireShow = true;
          this.$store.commit('SET_NEXT_STEP');
        }
      } else {
        await this.handleSubmit();
      }
    },
    async handleSubmit(data = null) {
      if (this.statement.curDep?.questionnaire && this.questionnaire?.enabled) {
        if (this.questionnaireShow) {
          this.questionnaireShow = false;
          await this.onSubmit(data);
        } else {
          this.questionnaireShow = true;
        }
      } else {
        await this.onSubmit();
      }
    },
    async onSubmit(qData = null) {
      this.$globalLoading.show();
      const statementData = new FormData();
      statementData.append('childId', this.statement.child);
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in this.statement.languages) {
        // noinspection JSUnfilteredForInLoop
        statementData.append('lang[]', this.statement.languages[i]);
      }
      statementData.append('department', this.statement.curDep.value);
      statementData.append('locality', this.statement.locality_id);
      if (this.statement.firstSchool.id) {
        if (this.statement.curDep?.nedbIntegration) {
          statementData.append('firstSchool', this.statement.firstSchool.id);
          statementData.append('secondSchool', this.statement.secondSchool.id);
          statementData.append('thirdSchool', this.statement.thirdSchool.id);
          statementData.append('fourthSchool', this.statement.fourthSchool.id);
          statementData.append('schoolLangs', JSON.stringify({
            firstSchool: this.statement.firstSchool.lang,
            secondSchool: this.statement.secondSchool.lang,
            thirdSchool: this.statement.thirdSchool.lang,
            fourthSchool: this.statement.fourthSchool.lang,
          }));
          statementData.append('lang[]', 1);
        } else {
          statementData.append('is_organization_statement', 1);
          statementData.append('organization_id', this.statement.firstSchool.id);
        }
      }
      statementData.append('soc_status', this.statement.soc_status_id);
      statementData.append('type', this.statement.type);

      const queueType = this.statement.queueType > 0;
      const socStatus = [6, 8].includes(this.statement.soc_status_id);
      const isVoucher = this.isVoucherStatement;
      const canSelectLgot = this.canSelectLgot
        || this.statement.lgotTypeOptions.length > 0
        || this.statement.soc_status_id === 13;

      if ((queueType || socStatus || isVoucher) && canSelectLgot) {
        const lgotType = ([6, 8, 13].includes(this.statement.soc_status_id)
          && !this.ignoreRshepSocStatus)
          ? this.statement.soc_status_id
          : this.statement.lgotType.value;

        statementData.append('lgot_type', lgotType);
        statementData.append('queue_type', this.statement.queueType);

        Object.entries(this.statement.lgotTypeDocuments).forEach(([name, file]) => {
          statementData.append(name, file);
        });
      }

      if (this.statement.type === 1) {
        statementData.append('diseases', parseInt(this.statement.correction, 10));
        statementData.append('document_disease', this.statement.statementDocumentDisease);
      }
      if (
        this.showNedbSpecialTypes && this.statement.nedbCategory?.value
        && !this.ignoreRshepSocStatus
      ) {
        statementData.append('diseases', parseInt(this.statement.nedbCategory.value, 10));
        statementData.append('document_disease', this.statement.statementDocumentDisease);
        statementData.append('consultation_date', this.statement.nedbConsultationDate.value);
        statementData.append('conclusion', this.statement.nedbConclusion.value);
        statementData.append('recommendation', this.statement.nedbRecommendation.value);
        statementData.append('conclusion_list_number', this.statement.nedbConclusionNumber.value);
      }

      if (this.statement.type === 3) {
        statementData.append('sanatorium', parseInt(this.statement.sanatorium, 10));
        statementData.append('document_sanatorium', this.statement.sanatoriumDocumentDisease);
      }

      statementData.append('ignore_rshep_soc_status', this.ignoreRshepSocStatus);
      statementData.append('agreement', this.license);

      if (this.statement.curDep?.questionnaire) {
        statementData.append('questionnaire', JSON.stringify(qData));
        this.questionnaireData = qData;
      }

      let uri = this.uriSubmit;
      if (this.statement.type === 1) {
        uri = this.uriSubmitDisease;
      }
      if (this.statement.type === 3) {
        uri = this.uriSubmitSanatorium;
      }

      if (this.fromStatementCreateView) {
        statementData.append('dontRedirect', 1);
      }

      const res = await requestWrapper.call(this, {
        method: 'POST',
        url: uri,
        data: statementData,
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      this.$globalLoading.hide();
      if (res.error) {
        this.$globalLoading.hide();
        if (res.data.errors && res.errorType === 422 && !this.statement.curDep?.questionnaire) {
          this.$notify({
            text: res.data.errors[Object.keys(res.data.errors)[0]][0],
            type: 'error',
          });
        }
        if (this.statement.curDep?.questionnaire) {
          Helper.handlerResponseErrorNew(this, res);
          this.questionnaireShow = true;
        }
      } else if (this.fromStatementCreateView) {
        this.$emit('send-data', true);
      }
    },
    async checkBenefits() {
      if (this.isVoucherStatement && this.uriCheckChild) {
        this.$globalLoading.show();
        const res = await requestWrapper.call(this, {
          url: this.uriCheckChild,
          params: { child_id: this.statement.child, q_type: this.statement.type },
        });
        this.$globalLoading.hide();
        if (res.error) {
          this.statement.child = null;
          return;
        }
      }

      if (this.statement.curDep.value != null) {
        this.statement.queueType = 0;
        this.statement.soc_status_id = 0;
        this.statement.lgotTypeDocuments = {};
        this.statement.lgotTypeDocumentRules = {};
        this.$globalLoading.show();
        this.benefits.forEach((benefit) => {
          // eslint-disable-next-line no-param-reassign
          benefit.disable = !(benefit.value === 0);
        });

        const response = await requestWrapper({
          method: 'POST',
          url: this.statement.curDep?.nedbIntegration
            ? this.uriCheckBenefitsNedb : this.uriCheckBenefits,
          data: {
            child_id: this.statement.child,
            statementType: this.statement.type,
          },
        });

        if (response.error && response.data.errors && response.errorType === 422) {
          this.$notify({
            text: response.data.errors[Object.keys(response.data.errors)[0]][0],
            type: 'error',
          });
        }

        if (!response.error) {
          this.socialStatusExist = response.data.socialStatusExist;
          if (response.data.socialStatusExist) {
            if (this.statement.curDep?.nedbIntegration) {
              this.benefits = response.data.benefitTypes;
            }
            this.benefits.forEach((benefit) => {
            // eslint-disable-next-line no-param-reassign
              benefit.disable = !(
                response.data.socialStatusID === benefit.value
              || benefit.value === 0
              );
            });
            this.statement.soc_status_id = response.data.socialStatusID;
            this.statement.zagsDocument = response.data.zagsDocument;
            this.showIgnoreRshepSocStatus = true;

            if (response.data.socialStatusID === 13 && this.statement.type === 0) {
              this.statement.queueType = 2;
              this.getLgotTypeDocuments(response.data.socialStatusID);
            }
            if (response.data.showNedbSpecialTypes) {
              this.statement.lgotType.value = response.data.socialStatusID;
              await this.checkSpecialType();
              this.$forceUpdate();
            }
            if (this.ignoreRshepSocStatus) {
              this.showNedbSpecialTypes = response.data.showNedbSpecialTypes ?? false;
            }
            this.nedbSpecialTypesRequired = response.data.specialTypeRequired ?? false;
          }
        }
        this.$globalLoading.hide();
      }
    },

    clearLgotFile(filename) {
      this.statement.lgotTypeDocuments[filename] = null;
    },
    lgotFileChange() {
      const file = this.$refs.lgotFileInput.files[0];
      if (file) {
        this.statement.lgotTypeDocuments[this.activeLgotFile] = file;
      }
      this.activeLgotFile = null;
      this.$refs.lgotFileInput.value = null;
    },
    triggerLgotFile(filename) {
      this.activeLgotFile = filename;
      this.$refs.lgotFileInput.click();
    },
    clearFile(filename) {
      this.statement[filename] = null;
    },
    fileChange() {
      const file = this.$refs.fileInput.files[0];
      if (file) {
        this.statement[this.activeFile] = file;
      }
      this.activeFile = null;
      this.$refs.fileInput.value = null;
    },
    triggerFile(filename) {
      this.activeFile = filename;
      this.$refs.fileInput.click();
    },
    async getLgotTypeDocuments(lgotType = null) {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypeDocuments,
        params: {
          lgotType: lgotType ?? this.statement.lgotType.value,
          departmentId: this.statement.curDep.value,
          childId: this.statement.child,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
        this.statement.lgotTypeDocuments = {};
        this.statement.lgotTypeDocumentRules = {};
      } else {
        this.statement.lgotTypeDocuments = response.data.documents;
        this.statement.lgotTypeDocumentRules = response.data.documentRules;
      }
      if (this.statement.curDep?.nedbIntegration) {
        await this.checkSpecialType();
      }
    },
    async checkSpecialType() {
      if (this.statement.curDep.value) {
        this.$globalLoading.show();
        const response = await requestWrapper({
          method: 'POST',
          url: this.uriCheckSpecialType,
          params: {
            lgotType: this.statement.lgotType.value,
            childId: this.statement.child,
            departmentId: this.statement.curDep.value,
          },
        });

        if (!response.error) {
          if (response.data.category) {
            this.nedbCategories = response.data.category;
            this.statement.nedbCategory = response.data.selectedCategory;
            this.statement.nedbConsultationDate = response.data.consultation_date;
            this.statement.nedbConclusion = response.data.conclusion;
            this.statement.nedbRecommendation = response.data.recommendation;
            this.statement.nedbConclusionNumber = response.data.conclusion_list_number;
            this.statement.needDiseaseDocument = response.data.needDiseaseDocument;
            this.showNedbSpecialTypes = true;
          } else {
            // eslint-disable-next-line max-len
            this.showNedbSpecialTypes = [6, 8].indexOf(this.statement.soc_status_id) !== -1 && !this.ignoreRshepSocStatus;
          }
        }
        this.$globalLoading.hide();
      }
    },
    async clearLgots() {
      if (
        !this.ignoreRshepSocStatus
        && this.statement.queueType === 0
        && this.statement.curDep?.nedbIntegration
      ) {
        this.showNedbSpecialTypes = [6, 8].indexOf(this.statement.soc_status_id) !== -1;
      } else {
        this.showNedbSpecialTypes = false;
      }
      if (this.statement.curDep?.nedbIntegration && this.statement.queueType === 1) {
        this.$notify({
          text: this.trans('notifications.nedb_extraordinary_queue_warning'),
          type: 'warn',
        });
        this.clearSchool();
      }
      await this.loadLgotTypeOptions();
      this.statement.lgotType = {
        value: 0,
        label: this.trans('statement.socStatuses.no_lgot'),
      };
      this.statement.lgotTypeDocuments = {};
      this.statement.lgotTypeDocumentRules = {};
    },
    async loadLgotTypeOptions() {
      const response = await requestWrapper({
        method: 'GET',
        url: this.uriGetLgotTypes,
        params: {
          queueType: this.statement.queueType,
          statementType: this.statement.type,
          departmentId: this.statement.curDep.value,
        },
      });

      if (response.message) {
        this.$notify({
          text: response.message,
          type: 'error',
        });
      }
      this.statement.lgotTypeOptions = response.data;
      if (this.statement.queueType > 0) {
        this.showIgnoreRshepSocStatus = false;
      } else if (this.statement.soc_status_id > 0) {
        this.showIgnoreRshepSocStatus = true;
      }
    },
    canViewSchoolSelect(number) {
      if (number === 1) {
        return this.optionsOrganization.length > 1;
      }
      if (number === 2) {
        return this.statement.curDep?.nedbIntegration
          && this.optionsOrganization.length > 2;
      }
      if (number === 3) {
        return this.statement.curDep?.nedbIntegration && !this.isSpecialStatementType
          && this.optionsOrganization.length > 3;
      }
      if (number === 4) {
        return this.statement.curDep?.nedbIntegration && !this.isSpecialStatementType
          && this.optionsOrganization.length > 4;
      }
      return false;
    },
    selectSchool(e, number) {
      if (number === 1) {
        this.statement.firstSchool.name = e.label;
        this.statement.firstSchool.langs = e.langs;
      }
      if (number === 2) {
        this.statement.secondSchool.name = e.label;
        this.statement.secondSchool.langs = e.langs;
      }
      if (number === 3) {
        this.statement.thirdSchool.name = e.label;
        this.statement.thirdSchool.langs = e.langs;
      }
      if (number === 4) {
        this.statement.fourthSchool.name = e.label;
        this.statement.fourthSchool.langs = e.langs;
      }
    },
    clearSchool() {
      this.statement.firstSchool.id = null;
      this.statement.firstSchool.lang = null;
      this.statement.secondSchool.id = null;
      this.statement.secondSchool.lang = null;
      this.statement.thirdSchool.id = null;
      this.statement.thirdSchool.lang = null;
      this.statement.fourthSchool.id = null;
      this.statement.fourthSchool.lang = null;
    },
    handleIgnoreSocial() {
      const isSpecialStatus = [6, 8].includes(this.statement.soc_status_id);
      const hasNedbIntegration = this.statement.curDep?.nedbIntegration;

      this.showNedbSpecialTypes = isSpecialStatus && hasNedbIntegration
        && !this.ignoreRshepSocStatus;

      this.statement.lgotTypeDocuments = {};
      this.statement.lgotTypeDocumentRules = {};
    },
    onLanguageChange() {
      // позволяет выбирать только 1 значение из чекбоксов (для корр и санаторных ВО)
      if (this.isSpecialStatementType && this.isVoucherStatement) {
        const lastSelected = this.statement.languages[this.statement.languages.length - 1];
        this.statement.languages = lastSelected ? [lastSelected] : [];
      }
    },
  },
};
