import { QIcon } from '@quasar/components';
import axios from 'axios';
import VNoResults from '@vjs/components/VNoResults';
import VVoucherFcReportCalcTable from './components/VVoucherFcReportCalcTable';
import VVoucherFcReportDesc from './components/VVoucherFcReportDesc';
import VVoucherFcReportTable from './components/VVoucherFcReportTable';

export default {
  name: 'VVoucherFcReport',
  components: {
    QIcon,
    VVoucherFcReportCalcTable,
    VVoucherFcReportDesc,
    VVoucherFcReportTable,
    VNoResults,
  },
  props: {
    apiConfig: {
      type: Object,
      default: () => ({
        getData: null,
        export: null,
        back: null,
        exportName: null,
      }),
      required: true,
    },
  },
  data() {
    return {
      data: {
        desc: [],
        calcTable: { data: [], cols: [] },
        table: { data: [], cols: [], bottomRow: {} },
      },
    };
  },

  async mounted() {
    await this.$trans.add(['label', 'button', 'fc_reports', 'placeholder', 'warning_text']);
    await requestGetDataHelper(this, {
      url: this.apiConfig.getData,
      fullRes: true,
      hardSet: true,
      variableName: 'data',
    });
  },

  methods: {
    async handleExport() {
      this.$globalLoading.show();
      await axios({
        url: this.apiConfig.export,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement('a');

        fileLink.href = fileURL;

        fileLink.setAttribute('download', this.apiConfig.exportName || this.trans('label.default_export_name'));
        document.body.appendChild(fileLink);

        fileLink.click();
      }).catch(async (err) => {
        let parsedError = null;
        await err.response.data.text().then((text) => {
          parsedError = JSON.parse(text);
        });
        if (parsedError.errorType === 'success') {
          this.$notify({
            type: 'success',
            text: parsedError.message,
          });
        } else {
          this.$notify({
            type: 'error',
            title: window.L.error,
            text: parsedError.message || this.trans('notice.error_on_server'),
          });
        }
      })
        .finally(() => {
          this.$globalLoading.hide();
        });
    },
  },
};
