<template>
  <q-form
    autocorrect="off"
    autocapitalize="off"
    autocomplete="off"
    spellcheck="false"
  >
    <v-form-inform
      v-if="bodyText"
      class="mb-20"
      :message="bodyText"
    />
    <div class="row-sort-between-btn">
      <q-btn
        v-for="(ctrl, key) in controls"
        :key="key"
        :label="ctrl.label"
        :color="ctrl.color"
        width="15"
        no-caps
        @click="request(ctrl)"
      />
    </div>
  </q-form>
</template>

<script>
import { requestWrapper } from '@vjs/helpers';
import { Helper } from '@common/src/helpers';

export default {
  name: 'ConfirmCustomActions',
  props: {
    bodyText: {
      type: String,
      default: '',
    },
    controls: {
      type: Array,
      required: true,
    },
  },
  methods: {
    async request({ action: url, method = 'post', requestData }) {
      this.$globalLoading.show();

      const res = await requestWrapper.call(this, {
        url,
        method,
        [method === 'get' ? 'params' : 'data']: requestData,
      });

      if (!res.error) {
        this.$emit('onClose');
        this.$emit('onApprove');
      } else {
        Helper.handlerResponseErrorNew(this, res);
      }
      this.$globalLoading.hide();
    },
  },
};
</script>
